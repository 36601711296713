module.exports = {
  siteTitle: "BLD SIC 2019", // <title>
  shortSiteTitle: "SIC 2019", // <title> ending for posts and pages
  siteDescription: "BLD SIC 2019 - hosted by BLD San Francisco Bay Area",
  siteUrl: "https://sic.bldsfbayarea.com",
  // pathPrefix: "/",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "Christopher Trinidad",

  /* info */
  headerTitle: "SIC 2019",
  headerSubTitle: "hosted by BLD SF Bay Area",

  /* manifest.json */
  manifestName: "SIC 2019",
  manifestShortName: "SIC 2019", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  // social
  authorSocialLinks: [
    { name: "facebook", url: "http://facebook.com/" }
  ]
};
