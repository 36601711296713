export function getScreenWidth() {
  if (typeof window !== `undefined`) {
    return window.innerWidth;
  }
}

export function isWideScreen() {
  if (typeof window !== `undefined`) {
    const windowWidth = window.innerWidth;
    const mediaQueryL = 1024;

    return windowWidth >= mediaQueryL;
  }
}

export function timeoutThrottlerHandler(timeouts, name, delay, handler) {
  if (!timeouts[name]) {
    timeouts[name] = setTimeout(() => {
      timeouts[name] = null;
      handler();
    }, delay);
  }
}

var firebaseConfig = {
  apiKey: "AIzaSyDyFv8mcdTGgJKO8koRLjLPLciZ34lcUMI",
  authDomain: "bld-sic-2019.firebaseapp.com",
  databaseURL: "https://bld-sic-2019.firebaseio.com",
  projectId: "bld-sic-2019",
  storageBucket: "bld-sic-2019.appspot.com",
  messagingSenderId: "495531551856"
};

let firebaseInstance
export const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance
  }

  firebase.initializeApp(firebaseConfig)
  firebaseInstance = firebase

  return firebase
}
