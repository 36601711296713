import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Item = props => {
  const { theme, item: { label, to, external, icon: Icon } = {}, onClick } = props;

  function handleClick () {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.assign("fb://profile/305514150282323");
    } else if(/android/i.test(userAgent)) {
      window.location.assign("fb://profile/305514150282323");
    } else {
      window.open("https://www.facebook.com/groups/305514150282323", "_blank");
    }
  }

  return (
    <React.Fragment>
      <li className={"hiddenItem" in props ? "hiddenItem" : "item"} key={label}>
      {external
        ? <a className={"hiddenItem" in props ? "inHiddenItem" : ""} onClick={handleClick}>{Icon && <Icon />} {label}</a>
        : <Link
            to={to}
            className={"hiddenItem" in props ? "inHiddenItem" : ""}
            onClick={onClick}
            data-slug={to}
          >
            {Icon && <Icon />} {label}
          </Link>
      }
      </li>


      {/* --- STYLES --- */}
      <style jsx>{`
        .item,
        .showItem {
          background: transparent;
          transition: all ${theme.time.duration.default};
          display: flex;
          align-items: center;

          :global(a) {
            padding: ${theme.space.inset.s};
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          :global(a:hover) {
            color: ${theme.color.brand.primary};
          }

          :global(svg) {
            margin: 0;
            font-size: 28px;
          }
        }

        :global(.itemList .hideItem) {
          display: none;
        }

        @from-width desktop {
          .item {
            :global(a) {
              color: ${theme.text.color.primary};
              padding: ${theme.space.inset.s};
              transition: all ${theme.time.duration.default};
              border-radius: ${theme.size.radius.small};
            }

            :global(.homepage):not(.fixed) & :global(a) {
              color: ${theme.text.color.primary};
            }

            :global(a:hover) {
              color: ${theme.color.brand.primary};
              background: color(white alpha(-60%));
            }

            :global(svg) {
              transition: all ${theme.time.duration.default};
            }

            &:hover :global(svg) {
              fill: ${theme.color.brand.primary};
              opacity: 1;

              :global(.hero) & :global(svg) {
                fill: green;
              }
            }
          }

          .showItem {
            display: none;
          }

          .hiddenItem {
            text-align: left;
            padding: ${theme.space.xs};

            & :global(a.inHiddenItem) {
              color: ${theme.text.color.primary};
              &:hover {
                color: ${theme.color.brand.primary};
              }
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Item.propTypes = {
  item: PropTypes.object,
  hidden: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  theme: PropTypes.object.isRequired
};

export default Item;
